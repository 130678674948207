import React, { useContext } from "react";

export const APIContext = React.createContext({});

const MODAL_ENDPOINT = "https://sshh12--red-cluster-backend.modal.run/";

function post(func, args = {}) {
  return fetch(MODAL_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      func: func,
      args: args,
      api_key: new URLSearchParams(window.location.search).get("pw"),
    }),
  }).then((response) => response.json());
}

export function useAPIControl() {
  return {
    post,
  };
}

export function useAPI() {
  const api = useContext(APIContext);
  return api;
}
